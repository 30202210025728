import { BaseQueryInterceptor, commonAPI } from "./commonApi";
import {
  ICategoryGame,
  ICreateGameSession,
  ICreateMondoSession,
  ICreateSpSession,
  IFavorite,
  IGameSession,
  IGetGamesClearList,
  IGetGamesRequest,
  IGetTokenSport,
  IGetTvGamesRequest,
  IProviderGame,
  IsFavoriteGame,
} from "../types/gamesTypes";
import { createApi } from "@reduxjs/toolkit/query/react";

export const gamesAPI = createApi({
  reducerPath: "gamesApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({
    getProviders: build.mutation<string[], void>({
      query: () => ({
        url: "/json-rpc/game-providers",
        method: "GET",
      }),
    }),
    getProvidersTV: build.mutation<string[], void>({
      query: () => ({
        url: "/json-rpc/tv-game-providers",
        method: "GET",
      }),
    }),
    getCategories: build.query<ICategoryGame[], void>({
      query: () => ({
        url: "/game-category",
        method: "GET",
      }),
    }),
    getGames: build.mutation<IProviderGame[], IGetGamesRequest>({
      query: (obj: Record<any, any>) => ({
        url: `/json-rpc/game-list`,
        method: "GET",
        params: obj,
      }),
    }),
    getGameList: build.mutation<IGetGamesClearList, IGetGamesRequest>({
      query: (obj: Record<any, any>) => ({
        url: "/json-rpc/game-list-clear",
        method: "GET",
        params: obj,
      }),
    }),
    getTvGames: build.mutation<IProviderGame[], IGetTvGamesRequest>({
      query: (obj: Record<any, any>) => ({
        url: "/json-rpc/tv-game-list-by-provider",
        method: "GET",
        params: obj,
      }),
    }),
    getTvGamesList: build.mutation<IGetGamesClearList, IGetTvGamesRequest>({
      query: (obj: Record<any, any>) => ({
        url: "/json-rpc/tv-game-list",
        method: "GET",
        params: obj,
      }),
    }),
    getProviderGames: build.mutation<IProviderGame, IGetGamesRequest>({
      query: (obj: Record<any, any>) => ({
        url: `/json-rpc/${obj?.provider}/games`,
        method: "GET",
        // params: {title: obj.title, page: obj.page, size: obj.size}
        params: obj,
      }),
    }),
    getSportToken: build.mutation<IGetTokenSport, void>({
      query: () => ({
        url: "/json-rpc/create-session/sport",
        method: "GET",
      }),
    }),
    createSession: build.mutation<IGameSession, ICreateGameSession>({
      query: (obj) => ({
        url: "/json-rpc/create-session",
        method: "POST",
        body: obj,
      }),
    }),
    createBetbySession: build.mutation<{ token: string }, void>({
      query: () => ({
        url: "/json-rpc/create-session/betby",
        method: "GET",
      }),
    }),
    createMondoSession: build.mutation<
      { sessionUrl: string },
      ICreateMondoSession
    >({
      query: (obj) => ({
        url: "/json-rpc/create-session/mondo",
        method: "GET",
        params: obj,
      }),
    }),
    createSpSession: build.mutation<{ sessionUrl: ICreateSpSession }, void>({
      query: () => ({
        url: "/json-rpc/create-session/sp-sport",
        method: "GET",
      }),
    }),
    createGaminghubSession: build.mutation<{ sessionUrl: string }, void>({
      query: () => ({
        url: "/json-rpc/create-session/gabsys",
        method: "GET",
      }),
    }),
    createSportSession: build.mutation<{ token: string }, void>({
      query: () => ({
        url: "/json-rpc/create-session/sport",
        method: "GET",
      }),
    }),
    createWalletSession: build.mutation<{ SessionUrl: string }, void>({
      query: () => ({
        url: "/json-rpc/create-session/wallet",
        method: "GET",
      }),
    }),
    createHorsesUraceSession: build.mutation<{ sessionUrl: string }, void>({
      query: () => ({
        url: "/json-rpc/create-session/urace",
        method: "GET",
      }),
    }),
    toggleFavoriteGameSession: build.mutation<IsFavoriteGame, IFavorite>({
      query: (obj) => ({
        url: "/user/favorite-games",
        method: "PUT",
        body: obj,
      }),
    }),
  }),
});

export const {
  useGetProvidersMutation,
  useGetProvidersTVMutation,
  useGetCategoriesQuery,
  useGetGamesMutation,
  useGetGameListMutation,
  useGetTvGamesMutation,
  useGetTvGamesListMutation,
  useGetProviderGamesMutation,
  useToggleFavoriteGameSessionMutation,
  useCreateSessionMutation,
  useGetSportTokenMutation,
  useCreateBetbySessionMutation,
  useCreateSportSessionMutation,
  useCreateMondoSessionMutation,
  useCreateWalletSessionMutation,
  useCreateHorsesUraceSessionMutation,
  useCreateGaminghubSessionMutation,
  useCreateSpSessionMutation,
} = gamesAPI;
