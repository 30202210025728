import { BASE_URL, BaseQueryInterceptor, commonAPI } from "./commonApi";

import {
  ICheckRecoverySession,
  ICode,
  IConfirmEmail,
  IConfirmNumber,
  ICreateRecoverySession,
  ICreateRecoverySessionEmail,
  ICreateVerification,
  IRegister,
  IRegisterLettobet,
  IResetPassword,
  ISendCode,
  IStatusRecoveryResponse,
  IUpdateMyPassword,
  IUpdateUserInfo,
  IUser,
  IUserBalance,
  IUserLogin,
  IUserState,
  IVerification,
} from "redux/types/userTypes";

import { createApi } from "@reduxjs/toolkit/query/react";

export const userAPI = createApi({
  reducerPath: "userApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({
    loginUser: build.mutation<IUser, IUserLogin>({
      query: (obj) => ({
        url: "/client-login",
        method: "POST",
        body: obj,
      }),
    }),
    getUserData: build.query<IUser, void>({
      query: () => ({
        url: "/auth/me",
        method: "GET",
      }),
    }),
    getUserBalance: build.mutation<IUserBalance, void>({
      query: () => ({
        url: "/info/balance",
        method: "GET",
      }),
    }),
    putMyPassword: build.mutation<string, IUpdateMyPassword>({
      query: (obj) => ({
        url: "/me/password",
        method: "PUT",
        body: obj,
      }),
    }),
    deleteUser: build.mutation<string, void>({
      query: () => ({
        url: "/logout",
        method: "DELETE",
      }),
    }),
    sendCode: build.mutation<ICode, ISendCode>({
      query: (obj) => ({
        url: "/signup/send-code",
        method: "POST",
        body: obj,
      }),
    }),
    confirmCode: build.mutation<string, IConfirmNumber>({
      query: (obj) => ({
        url: "/signup/confirm-number",
        method: "PATCH",
        body: obj,
      }),
    }),
    confirmEmail: build.mutation<string, IConfirmEmail>({
      query: (obj) => ({
        url: `/signup/confirm-email/${obj.link}`,
        method: "GET",
      }),
    }),
    registerUser: build.mutation<IUser, IRegister>({
      query: (obj) => ({
        url: "/signup",
        method: "POST",
        body: obj,
      }),
    }),
    registerUserLettobet: build.mutation<IUser, IRegisterLettobet>({
      query: (obj) => ({
        url: "/lettobet-signup",
        method: "POST",
        body: obj,
      }),
    }),
    registerCurrency: build.mutation<{ code: string }[], void>({
      query: (obj) => ({
        url: "/signup-currency",
        method: "GET",
      }),
    }),
    getAffilateCurrency: build.mutation<
      { id: number; code: string },
      { ref: string }
    >({
      query: (obj) => ({
        url: `/affiliates/currency/${obj.ref}`,
        method: "GET",
      }),
    }),
    createRecoverySession: build.mutation<
      IStatusRecoveryResponse,
      ICreateRecoverySession
    >({
      query: (obj) => ({
        url: "/password-recovery",
        method: "POST",
        body: obj,
      }),
    }),
    createRecoverySessionEmail: build.mutation<
      IStatusRecoveryResponse,
      ICreateRecoverySessionEmail
    >({
      query: (obj) => ({
        url: "/password-recovery-email",
        method: "POST",
        body: obj,
      }),
    }),
    recoveryPassword: build.mutation<string, IResetPassword>({
      query: (obj) => ({
        url: "/password-recovery",
        method: "PATCH",
        body: obj,
      }),
    }),
    checkRecoverySession: build.mutation<
      IStatusRecoveryResponse,
      ICheckRecoverySession
    >({
      query: (obj) => ({
        url: `/password-recovery/${obj.id}`,
        method: "GET",
      }),
    }),
    updateUserData: build.mutation<IUser, IUpdateUserInfo>({
      query: (obj) => ({
        url: `/my/full-data`,
        method: "PATCH",
        body: obj,
      }),
    }),
    getFullUserData: build.mutation<IUser, void>({
      query: (obj) => ({
        url: `/my/full-data`,
        method: "GET",
      }),
    }),
    createVerification: build.mutation<IVerification, ICreateVerification>({
      query: (obj) => ({
        url: `/verifications`,
        method: "POST",
      }),
    }),
    getVerificationStatus: build.query<IVerification, void>({
      query: (obj) => ({
        url: `/my-verifications-status`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserDataQuery,
  useGetUserBalanceMutation,
  useDeleteUserMutation,
  usePutMyPasswordMutation,
  useSendCodeMutation,
  useConfirmCodeMutation,
  useRegisterUserMutation,
  useCheckRecoverySessionMutation,
  useRecoveryPasswordMutation,
  useCreateRecoverySessionMutation,
  useUpdateUserDataMutation,
  useGetFullUserDataMutation,
  useCreateVerificationMutation,
  useGetVerificationStatusQuery,
  useRegisterUserLettobetMutation,
  useConfirmEmailMutation,
  useCreateRecoverySessionEmailMutation,
  useRegisterCurrencyMutation,
  useGetAffilateCurrencyMutation,
} = userAPI;
