export const setFavicon = (favicon: string) => {
    let faviconElement = document.createElement("link");
    faviconElement.setAttribute("rel", "icon");
    faviconElement.setAttribute("href", `/favicon/${favicon}.ico`);
    document.getElementsByTagName('head')[0].appendChild(faviconElement);
    document.getElementById('id_link_apple_touch_icon')?.setAttribute('href', `/favicon/${favicon}192.png`)
}

export const setTitle = (title: string) => {
    let titleElement = document.createElement('title')
    titleElement.innerText = title
    document.getElementsByTagName('head')[0].appendChild(titleElement);
}

export const setDescription = (description: string) => {
    let descriptionElement = document.createElement('meta')
    descriptionElement.name = 'description'
    descriptionElement.content = description
    document.getElementsByTagName('head')[0].appendChild(descriptionElement);
}


export const setManifest = (site_name: string) => {
        document.getElementById('head_link_manifest')?.setAttribute('href', `/manifest_${site_name}.json`)
}

/*
export const setManifest = async (manifest: IManifest) => {

    const getBase64FromUrl = async (name: string, resolution: string) => {
        const url = require(`../favicons/${name}${resolution}.png`)
        const data = await fetch(url);
        const blob = await data.blob();
        let result;
        await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        }).then((pld: any) => {
            result = pld
        });
        // console.log(`result ${name}${resolution} = `, result)
        return result
    }


    let manifestData = {
        "name": manifest.NAME,
        "short_name": manifest.SHORT_NAME,
        "start_url": manifest.START_URL,
        "scope": manifest.START_URL,
        "display": manifest.DISPLAY,
        "theme_color": manifest.THEME_COLOR,
        "background_color": manifest.BACKGROUND_COLOR,
        "description": manifest.DESCRIPTION,
        "id": manifest.ID,
        "icons": [{
            "src": await getBase64FromUrl(manifest.ICON, '') ?? '',
            "sizes": "32x32",
            "type": "image/png"
        }, {
            "src": await getBase64FromUrl(manifest.ICON, '192') ?? '',
            "sizes": "192x192",
            "type": "image/png"
        }, {
            "src": await getBase64FromUrl(manifest.ICON, '512') ?? '',
            "sizes": "512x512",
            "type": "image/png"
        }],
        "lang": manifest.LANG ?? "en",
        "dir": manifest.DIR ?? "auto"
    }

    const jsonManifest = JSON.stringify(manifestData)
    const blobManifest = new Blob([jsonManifest], {type: 'application/json'})
    const manifestUrl = URL.createObjectURL(blobManifest)
    document.getElementById('head_link_manifest')?.setAttribute('href', manifestUrl)
}*/
