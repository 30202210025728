import { ISettings } from "./interfaces/ISettings";

type languages = "en" | "fr";

const crazywin_settings: ISettings<languages> = {
  API_DEV_URL: "https://api.crazywin365.com",
  API_PROD_URL: "https://api.crazywin365.com",

  MIRRORS: {},

  OTHER: {
    CURRENCY: {
      TITLE: "TND",
      KEY: "TND",
    },
    LANGUAGES: {
      en: {
        VISIBLE: true,
        DEFAULT: false,
        DIRECTION: "rtl",
        TITLE: "English",
        KEY: "en",
      },
      fr: {
        VISIBLE: true,
        DEFAULT: true,
        DIRECTION: "rtl",
        TITLE: "French",
        KEY: "fr",
      },
    },
    VARIANT: "crazywin",
    HEADER: [
      "HELP",
      "SPORT",
      "LIVE",
      "VIRTUAL_GAMES",
      "GAMES",
      "TV_GAMES",
      "EVOLUTION",
      "LUDO",
      "FAVORITE_GAMES",
      "PROMOTIONS",
    ],
    LOGIN_LOGO: "crazywin",
    FOOTER: {
      MENU: ["HOME", "SPORT", "LIVE", "GAMES", "TV_GAMES"],
      PAYMENTS: [],
    },
    MOBILE_MENU: {
      HOME: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      SPORT: {
        VISIBLE: true,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      ESPORT: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      LIVE: {
        VISIBLE: true,
        ROW: 1,
        COLUMN: 2,
        SPAN: 1,
      },
      VIRTUAL_GAMES: {
        VISIBLE: true,
        ROW: 2,
        COLUMN: 3,
        SPAN: 1,
      },
      GAMES: {
        VISIBLE: true,
        ROW: 2,
        COLUMN: 2,
        SPAN: 1,
      },
      TV_GAMES: {
        VISIBLE: true,
        ROW: 2,
        COLUMN: 1,
        SPAN: 1,
      },
      EVOLUTION_MOBILE: {
        VISIBLE: true,
        ROW: 4,
        COLUMN: 1,
        SPAN: 2,
      },
      AMATIC: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      FAVORITE_GAMES: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      PROMOTIONS: {
        VISIBLE: true,
        ROW: 3,
        COLUMN: 3,
        SPAN: 1,
      },
      HORSES: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 1,
        SPAN: 1,
      },
      TURBO_GAMES: {
        VISIBLE: false,
        ROW: 1,
        COLUMN: 3,
        SPAN: 1,
      },
      LUDO: {
        VISIBLE: true,
        ROW: 1,
        COLUMN: 3,
        SPAN: 1,
      },
      PRAGMATIC: {
        VISIBLE: true,
        ROW: 3,
        COLUMN: 1,
        SPAN: 2,
      },
      HALL_OF_FAME: {
        VISIBLE: true,
        ROW: 4,
        COLUMN: 3,
        SPAN: 1,
      },
    },
  },

  SEO: {
    TITLE: "CrazyWin365.com",
    SITE_NAME: "CrazyWin365",
    LOGO_AND_FAVICON: "crazywin",
    DESCRIPTION: "Play with CrazyWin365",
    KEYWORDS: ["CrazyWin", "CrazyWin365", "365"],
  },

  THEME: {
    VARIANT: "crazywin",
    DEFAULT: "dark",
    TOGGLE: false,
  },

  ACCESS: {
    SPORT: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    BONUSES: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    LIVE_SPORT: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    LOGIN: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    REGISTER: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    RESET_PASSWORD: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    RECOVERY_PASSWORD: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    CONFIRM_EMAIL: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    GAME: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    GAMES: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    TV_GAMES: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    HORSES: {
      VISIBLE: false,
      PERMISSIONS: {
        URACE: false,
        WALLET: false,
      },
    },
    PROFILE: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    PROFILE_PERSONAL_DATA: {
      VISIBLE: true,
      PERMISSIONS: {
        ID: true,
        USERNAME: false,
        PHONE: false,
        EMAIL: false,
        NAME: false,
        SURNAME: false,
        ADDRESS: false,
        ID_CARD: false,
        BALANCE: true,
        CURRENCY: true,
        FIRST_NAME: false,
        SECOND_NAME: false,
        LAST_NAME: false,
        BIRTHDAY: false,
        GET_FULL_DATA: false,
        UPDATE_USER_INFO: false,
      },
    },
    PROFILE_DEPOSIT: {
      VISIBLE: false,
      PERMISSIONS: {
        RUNPAY: false,
        VOUCHER: false,
        MAKSI_PAPARA: false,
        MAKSI_CRYPTO: false,
        MAKSI_HAYHAY: false,
        MAKSI_PAYFIX: false,
        MAKSI_CREDIT_CARD: false,
        MAKSI_PEP: false,
        MAKSI_BANK: false,
        HASHBON: false,
      },
    },
    PROFILE_USER_DOCUMENT: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    PROFILE_WITHDRAWAL: {
      VISIBLE: false,
      PERMISSIONS: {
        RUNPAY: false,
        BANK: false,
        MAKSI_PAPARA: false,
        MAKSI_CRYPTO: false,
        MAKSI_HAYHAY: false,
        MAKSI_PAYFIX: false,
        MAKSI_PEP: false,
        MAKSI_BANK: false,
        HASHBON: false,
      },
    },
    PROFILE_OPERATION_HISTORY: {
      VISIBLE: true,
      PERMISSIONS: {
        TYPE: true,
        DATE: true,
        AMOUNT: true,
        STATUS: true,
        ID: false,
        DECLAINE_REASON: false,
      },
    },
    PROFILE_BET_HISTORY: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    PROFILE_CASHBACK_HISTORY: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    PROFILE_SETTINGS: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    PROFILE_MESSAGES: {
      VISIBLE: false,
      PERMISSIONS: {},
    },
    PROMOTIONS: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    PROMOTION: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
    HALL_OF_FAME: {
      VISIBLE: true,
      PERMISSIONS: {},
    },
  },

  SERVICES_DEV: {
    SP: {
      WALLETCODE: 202100,
      SKINID: "crazywin365",
      INSTANCE: "p002",
      API_INTEGRATION:
        "https://sportsfront.takwira216.net/api/Integration/crazywin365?v=1",
    },
    TAWK: {
      PROPERTY_ID: "1ha4klvv2",
      ID: "650054bb0f2b18434fd81399",
    },
    YM: {
      KEY: "89932680",
    },
  },
  SERVICES_PROD: {
    SP: {
      WALLETCODE: 202100,
      SKINID: "crazywin365",
      INSTANCE: "p002",
      API_INTEGRATION:
        "https://sportsfront.takwira216.net/api/Integration/crazywin365?v=1",
    },
    TAWK: {
      PROPERTY_ID: "1ha4klvv2",
      ID: "650054bb0f2b18434fd81399",
    },
    YM: {
      KEY: "89932680",
    },
    GA: {
      ID: "G-2T2RSQ26DH",
    },
  },
};

export default crazywin_settings;
