import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";

const RtkCommonMiddleware: Middleware = (api: MiddlewareAPI) => (dispatch) => (action) => {
    const {payload, type, meta, error} = action;
    const currentType = type.split("/")[0];
    const requestName = type.split("/")[1];
    const status = type.split("/")[2];
    const method = meta?.baseQueryMeta?.request?.method || payload?.method;

    // console.log('==============================================')
    // console.log('action = ', action)
    // console.log('payload = ', payload)
    // console.log('type = ', type)
    // console.log('currentType = ', currentType)
    // console.log('meta = ', meta)
    // console.log('error = ', error)
    // console.log('status = ', status)
    // console.log('method = ', method)
    // console.log('requestName = ', requestName)

    return dispatch(action)
}


export default RtkCommonMiddleware;
