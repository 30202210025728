import axios from 'axios';
import { ProjectSettings } from '../../../config';
import { errorHandler, requestHandler, responseHandler } from './interceptors';

const apiInstance = axios.create({
  baseURL: `${ProjectSettings.api()}/api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// @ts-ignore
apiInstance.interceptors.request.use(requestHandler);
apiInstance.interceptors.response.use(responseHandler, errorHandler, {});

export default apiInstance;
