import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {BaseQueryInterceptor} from "./commonApi";
import {IHighRollerItem} from "../types/highRollerTypes";


export const highRollerApi = createApi({
    reducerPath: 'highRollerApi',
    baseQuery: BaseQueryInterceptor,
    endpoints: (build) => ({
        getHighRoller: build.mutation<IHighRollerItem[], void>({
            query: () => ({
                url: `/info/high-roll`,
                method: 'GET',
            }),
        })
    })
})

export const {
    useGetHighRollerMutation
} = highRollerApi
