import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {useTypedDispatch, useTypedSelector} from "../../hooks/useTypedRedux";
import {betbySelector} from "../../redux/selectors/appSelector";
import {setBetbyEvent} from "../../redux/reducers/appSlice";

export const SportProvider = () => {
    const { pathname, state } = useLocation();
    const betbyStore = useTypedSelector(betbySelector);
    const dispatch = useTypedDispatch()

    useEffect(() => {
        const betbyTag = document.getElementById('betby');
        if (!pathname.includes('/sport') && !pathname.includes('/live-sport')) {
            betbyTag!.hidden = true;
            if (betbyStore) {
                betbyStore.clean();
                dispatch(setBetbyEvent(null))
            }
        } else {
            betbyTag!.hidden = false;
        }

        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
