import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {ProjectSettings} from "config";

const services = ProjectSettings.services()

const FacebookService = (): void => {

    const body = document.getElementsByTagName('body')[0]

    if (body === undefined) return

    if (services?.FB === undefined) return

    ReactPixel.init(services.FB.META_PIXEL)
    ReactPixel.pageView()

    // const script = document.createElement('script')
    // script.setAttribute('type', 'text/javascript')
    // script.innerHTML = `
    //             !function(f,b,e,v,n,t,s)
    //             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //                 n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //                 if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //                 n.queue=[];t=b.createElement(e);t.async=!0;
    //                 t.src=v;s=b.getElementsByTagName(e)[0];
    //                 s.parentNode.insertBefore(t,s)}(window, document,'script',
    //             'https://connect.facebook.net/en_US/fbevents.js');
    //             fbq('init', '${services['FB']['META_PIXEL']}');
    //             fbq('track', 'PageView');`
    //
    // const noscript = document.createElement('noscript')
    //
    // const img = document.createElement('img')
    // img.src = `https://www.facebook.com/tr?id=${services['FB']['META_PIXEL']}&ev=PageView&noscript=1`
    // img.alt = ''
    // img.style.height = '1px'
    // img.style.width = '1px'
    // img.style.display = 'none'
    // noscript.appendChild(img)
    //
    // body.appendChild(script)
    // body.appendChild(noscript)

    const head = document.getElementsByTagName('head')[0];

    if (head === undefined) return

    const meta = document.createElement('meta');
    meta.name = 'facebook-domain-verification';
    meta.content = services.FB.META_CONTENT;
/*    meta.content = '0jz314yv0lkx98jr3qoapwcrwdf33u'*/

    head.appendChild(meta)

}

export default FacebookService
