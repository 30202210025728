import { BaseQueryInterceptor } from "./commonApi";
import {
  IBet,
  IGetBets,
  IGetTransactions,
  ITransaction,
} from "../types/transactionTypes";
import { createApi } from "@reduxjs/toolkit/query/react";

export const transactionAPI = createApi({
  reducerPath: "transApi",
  baseQuery: BaseQueryInterceptor,
  endpoints: (build) => ({
    getTransactions: build.mutation<
      { count: number; data: ITransaction[] },
      IGetTransactions
    >({
      query: (obj: Record<any, any>) => ({
        url: `/transactions/player-operations`,
        method: "GET",
        params: obj,
      }),
    }),
    getBets: build.mutation<{ count: number; data: IBet[] }, IGetBets>({
      query: (obj: Record<any, any>) => ({
        url: `/transactions/player-bets`,
        method: "GET",
        params: obj,
      }),
    }),
  }),
});

export const { useGetBetsMutation, useGetTransactionsMutation } =
  transactionAPI;
